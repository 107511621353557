import React, { FC, ReactNode } from 'react'
import { Stack, Link, Text } from '@chakra-ui/react'

const Title = ({ children }: { children: ReactNode }) => (
  <Text
    as="h6"
    fontFamily="Montserrat-SemiBold"
    textTransform="uppercase"
    fontSize="0.875rem"
    lineHeight="18px"
    letterSpacing=".2px"
    color="baseDark"
    mb={2}
  >
    {children}
  </Text>
)

const FooterColumn: FC<FooterGlobal.FooterColumn> = ({ title, items }) => (
  <Stack align="flex-start">
    <Title>{title}</Title>
    {items &&
      items.map(child => (
        <Link
          key={child.name}
          title={child.title}
          target={child.target}
          fontFamily="Montserrat-Medium"
          fontSize="0.875rem"
          lineHeight="18px"
          letterSpacing=".2px"
          color="baseNeutral.700"
          href={child.href}
        >
          {child.name}
        </Link>
      ))}
  </Stack>
)

export default FooterColumn
