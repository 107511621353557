import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Desktop from './Desktop'
import Mobile from './Mobile'

interface Props {
  col1: FooterGlobal.FooterItem[]
  col2: FooterGlobal.FooterItem[]
  col3: FooterGlobal.FooterItem[]
}

const Footer: FC<Props> = ({ col1, col2, col3 }) => {
  const { t } = useTranslation()

  return <>
    <Mobile
      display={{ base: 'block', md: 'none' }}
      titleCol1={t('common:footerMobile.titleCol1')}
      col1={col1}
      titleCol2={t('common:footerMobile.titleCol2')}
      col2={col2}
      titleCol3={t('common:footerMobile.titleCol3')}
      col3={col3}
      copyright={t('common:footerMobile.copyright')}
    />
    <Desktop
      display={{ base: 'none', md: 'block' }}
      titleCol1={t('common:footer.titleCol1')}
      col1={col1}
      titleCol2={t('common:footer.titleCol2')}
      col2={col2}
      titleCol3={t('common:footer.titleCol3')}
      col3={col3}
      copyright={t('common:footerMobile.copyright')}
    />
  </>
}

export default Footer
