import React, { FC } from 'react'
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from 'react-icons/fa'
import { SocialButton } from 'src/uikit'
import {
  Accordion,
  Box,
  Text,
} from '@chakra-ui/react'
import style from 'styled-components'
import MobileMenuItem from './MobileMenuItem'

const LogoImg = style.img`
  margin: 20px auto auto;
  width: 5rem;
`

interface Props {
  display: any
  titleCol1: string
  col1: FooterGlobal.FooterItem[]
  titleCol2: string
  col2: FooterGlobal.FooterItem[]
  titleCol3: string
  col3: FooterGlobal.FooterItem[]
  copyright: string
}

const FooterMobile: FC<Props> = ({ display, titleCol1, col1, titleCol2, col2, titleCol3, col3, copyright }) => {
  return (
    <Box mb="9rem" display={display}>
      <Accordion allowToggle>
        <MobileMenuItem title={titleCol1} items={col1} />
        <MobileMenuItem title={titleCol2} items={col2} />
        <MobileMenuItem title={titleCol3} items={col3} />
      </Accordion>
      <Box p="6" textAlign="center">
        <SocialButton label="Facebook" href="https://www.facebook.com/carplannercom" icon={<FaFacebook color="#1b4832" />} />
        <SocialButton label="Instagram" href="https://www.instagram.com/carplanner_ita/" icon={<FaInstagram color="#1b4832" />} />
        <SocialButton label="Linkedin" href="https://www.linkedin.com/company/carplanner/" icon={<FaLinkedin color="#1b4832" />} />
        <SocialButton label="Twitter" href="https://twitter.com/CarPlannerIT" icon={<FaTwitter color="#1b4832" />} />
        <Box>
          <a href="https://pledge1percent.org/" target="_blank" rel="noreferrer">
            <LogoImg
              src="https://static1.carplanner.com/logos/Pledge1_ProudMember_Large.png"
              alt="Pledge 1% member"
              loading="lazy"
              decoding="async"
              width="89px"
              height="80px"
            />
          </a>
        </Box>
      </Box>
      <Box px="1rem">
        <Text
          fontFamily="Montserrat-Medium"
          fontSize="0.625rem"
          color="baseNeutral.700"
          textAlign="center"
        >
          {copyright}
        </Text>
      </Box>
    </Box>
  )
}

export default FooterMobile
