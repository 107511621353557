import React, { FC } from 'react'
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
  Stack,
} from '@chakra-ui/react'

interface Props {
  title: string
  items: FooterGlobal.FooterItem[]
}

const MobileMenuItem: FC<Props> = ({ title, items }) => {
  return <AccordionItem border="0" borderBottom="1px solid #dadde1">
    <h2>
      <AccordionButton p="m" _hover={{ background: 'none' }}>
        <Box
          flex="1"
          textAlign="left"
          fontSize="1rem"
          fontFamily="Montserrat-SemiBold"
          textTransform="uppercase"
        >
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Stack align="flex-start">
        {items &&
          items.map(child => (
            <Link
              key={child.name}
              title={child.title}
              target={child.target}
              fontFamily="Montserrat-Medium"
              fontSize="0.875rem"
              lineHeight="18px"
              letterSpacing=".2px"
              color="baseNeutral.700"
              href={child.href}
            >
              {child.name}
            </Link>
          ))
        }
      </Stack>
    </AccordionPanel>
  </AccordionItem>
}

export default MobileMenuItem
