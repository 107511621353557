import React, { FC } from 'react'
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from 'react-icons/fa'
import { SocialButton } from 'src/uikit'
import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import style from 'styled-components'
import FooterColumn from './Column'

const LogoImg = style.img`
  margin: 50px auto auto;
  width: 5rem;
`

interface Props {
  display: any
  titleCol1: string
  col1: FooterGlobal.FooterItem[]
  titleCol2: string
  col2: FooterGlobal.FooterItem[]
  titleCol3: string
  col3: FooterGlobal.FooterItem[]
  copyright: string
}

const FooterDesktop: FC<Props> = ({ display, titleCol1, col1, titleCol2, col2, titleCol3, col3, copyright }) => {
  return (
    <Box display={display}>
      <Container as={Stack} maxW="6xl" py={10}>
        <SimpleGrid templateColumns="1.4fr 1.4fr 0.8fr 0.8fr" spacing={8}>
          <FooterColumn title={titleCol1} items={col1} />
          <FooterColumn title={titleCol2} items={col2} />
          <FooterColumn title={titleCol3} items={col3} />
          <Box>
            <SimpleGrid templateColumns="repeat(4, 1fr)" spacing="0.2rem">
              <SocialButton label="Facebook" href="https://www.facebook.com/carplannercom" icon={<FaFacebook color="#1b4832" />} />
              <SocialButton label="Instagram" href="https://www.instagram.com/carplanner_ita/" icon={<FaInstagram color="#1b4832" />} />
              <SocialButton label="Linkedin" href="https://www.linkedin.com/company/carplanner/" icon={<FaLinkedin color="#1b4832" />} />
              <SocialButton label="Twitter" href="https://twitter.com/CarPlannerIT" icon={<FaTwitter color="#1b4832" />} />
            </SimpleGrid>
            <a href="https://pledge1percent.org/" target="_blank" rel="noreferrer">
              <LogoImg
                src="https://static1.carplanner.com/logos/Pledge1_ProudMember_Large.png"
                alt="Pledge 1% member"
                loading="lazy"
                decoding="async"
                width="89px"
                height="80px"
              />
            </a>
          </Box>
        </SimpleGrid>
      </Container>
      <Box pb={10}>
        <Text
          fontFamily="Montserrat-Medium"
          fontSize="0.625rem"
          color="baseNeutral.700"
          textAlign="center"
        >
          {copyright}
        </Text>
      </Box>
    </Box>
  )
}

export default FooterDesktop
